import React from "react";
import axios from "axios";
import DragDrop from "./DragDropZamin";
import config from "../../config"

class Fourth extends React.Component {
  state = {
    query: "",
    data: [],
    filteredData: [],
    isNumberSelected: false,
    chosenUser: "",
    chosenZamin: "",
    percentage: "",
    amount: "",
    time: "",
    fin: "",
    token: localStorage.getItem("token"),
    postTracks: [],
    searchedNumber: '',
    searchedName: '',
    searchedSurname: '',
    showSpan: false,
    openDragDrop: false,
    searchedZamin: '',
    zaminName: '',
    zaminSurname: '',
    showZaminSpan: false,
    userChecked: false,
    zaminChecked: false
  };

  findUser = (number) => {
    axios.post('http://'+config.ip+'/api/posts/getUser',
    { 
        token: this.state.token, 
        mobileNumber: number
    })
      .then(response => {
        
        console.log(response.data)
        this.setState({searchedName: response.data.name})
        this.setState({searchedSurname: response.data.surname})
        this.setState({showSpan: true})
      })
      .catch(err =>
      {
        console.log(err)
      });
}

findZamin = (number) => {
    axios.post('http://'+config.ip+'/api/posts/getUser',
    { 
        token: this.state.token, 
        mobileNumber: number
    })
      .then(response => {
        
        console.log(response.data)
        this.setState({zaminName: response.data.name})
        this.setState({zaminSurname: response.data.surname})
        this.setState({showZaminSpan: true})
      })
      .catch(err =>
      {
        console.log(err)
      });
}

  getPostTrack(chosenNumber) {
    axios.post('http://'+config.ip+'/api/posts/getPostTracks',{
      mobileNumber: chosenNumber 
    },
    {
      headers: {
        'x-access-token': this.state.token
      }})
      .then(data => 
        {
          //console.log("PostTracks")
          //console.log(data.data)
          this.setState({postTracks: data.data.postTracks})
        })
  }

  render() {
    return (
      <div style={{display: 'flex',
        flexDirection: 'row',}}>
            <div >
                <strong style={{display: 'block'}}>Kredit alan İstifadəçi</strong>
                <input
                style={{fontSize: 24}}
                    placeholder="Mobil nömrə"
                    value={this.state.searchedNumber}
                    onChange={(event) => this.setState({searchedNumber: event.target.value})}
                    onKeyUp={(event)=>
                        {
                            if(event.key=='Enter')
                            {
                                this.findUser(this.state.searchedNumber)
                            }
                        } }
                    />

                <br/>
                {this.state.showSpan &&
                <span style={{ border: '1px solid black', justifyContent: 'center', alignContent: 'center', alignItems: 'center', display: "block"}} onClick={() => 
                    {
                        this.getPostTrack(this.state.searchedNumber);
                        this.setState({openDragDrop: true})
                        this.setState({userChecked:true})
                    }
                }>
                    {this.state.searchedName}, {this.state.searchedSurname}, {this.state.searchedNumber} <input type="radio" checked={this.state.userChecked}/>

                </span>
                }
            </div>
      <div style={{marginLeft: 5}}>
        <strong style={{display: 'block'}}>Zamin İstifadəçi</strong>
        <input
            style={{fontSize: 24}}
            placeholder="Mobil Nömrə"
            value={this.state.searchedZamin}
            onChange={(event) => this.setState({searchedZamin: event.target.value})}
            onKeyUp={(event)=>
                {
                    if(event.key=='Enter')
                    {
                        this.findZamin(this.state.searchedZamin)
                    }
                } }
            />
        {this.state.showZaminSpan && 
        <span style={{ border: '1px solid black', justifyContent: 'center', alignContent: 'center', alignItems: 'center', display: "block"}}
        onClick={() =>                         this.setState({zaminChecked:true})
      }
              >
            {this.state.searchedZamin}, {this.state.zaminName}, {this.state.zaminSurname} <input type="radio" checked={this.state.zaminChecked}/>

        </span> }
      </div>
      {this.state.openDragDrop && 
      <DragDrop 
      token={this.state.token} 
      number={this.state.searchedZamin} 
      user={this.state.searchedNumber} 
      postTracks={this.state.postTracks}/>}

      </div>
    );
  }
}
export default Fourth;
