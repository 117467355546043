import React, { useState, useEffect } from 'react';
import './Zamin.css';
import Post from './SignedZaminPost';
import config from "../../config.js";


const Fifth = () => {
    const [posts, setPosts] = useState([]);
    const token = localStorage.getItem("token");

    console.log("Inside Third"+token)
    useEffect(() => {
      getDataFromDb();
    }, [])

    const  getDataFromDb = () => {
              fetch('http://'+config.ip+'/api/files/getSignedZaminFiles',
                {
                  headers: {
                    'x-access-token': token
                  }
                })
                  .then((userPosts) => userPosts.json())
                  .then((res) =>
                  {
                    console.log(res);
                    setPosts( res )
                  }
                );
          };

    return (
        <div className="feed1">
          {
                posts.map(post => (
                    <Post
                        key={post._id}
                        name={post.signedZaminURL}
                        mobileNumber={post.mobileNumber}
                        token={token}
                    />
                ))
            }

        </div>
    )
}

export default Fifth;