import React from "react";
import config from "../../config"
import axios from 'axios';

class Sixth extends React.Component {
  state = {
    password: "",
    rePass: "",
    token: localStorage.getItem("token"),
    searchedNumber: '',
    searchedName: '',
    searchedSurname: '',
    changePass: false,
    password: '',
    rePas: ''
  };

reset = () => {
    if(this.state.password != this.state.rePass)
    {
        alert("Şifrələr eyni deyil!")
    }
    else
    {
        console.log(this.state.password)
        let data = {
            mobileNumber: this.state.searchedNumber,
            password: this.state.password
        }
        axios.post("http://"+config.ip+"/users/resetPassword", data,
        {
            headers: {'x-access-token': this.state.token },
        }).then(res => 
            {alert(res.data.success)
             window.location.reload()
            })
    }
}

findUser = (number) => {
    axios.post('http://'+config.ip+'/api/posts/getUser',
    { 
        token: this.state.token, 
        mobileNumber: number
    })
      .then(response => {
        
        console.log(response.data)
        this.setState({searchedName: response.data.name})
        this.setState({searchedSurname: response.data.surname})
        
      })
      .catch(err =>
      {
        console.log(err)
      });
}

  render() {
    return (
        <div style={{  justifyContent: 'center', alignItems:'center' }}>
            <div style={{display: 'flex',
    flexDirection: 'row'}}>
            <input
            style={{fontSize: 32}}
                placeholder="Mobil nömrəyə görə axtar"
                value={this.state.searchedNumber}
                onChange={(event) => this.setState({searchedNumber: event.target.value})}
            />
            <button onClick={() => this.findUser(this.state.searchedNumber)}><img src={require('../../../assets/icons/search.png')}/></button>
            </div>
            <div>
                * Mobil nömrə +994 ilə başlamalıdır!
            </div>


        {
            this.state.searchedName && 
            <div style={{display: 'flex',
                justifySelf: 'center',
                border: '1px solid black',
                width: '60%'
                }}>
                <p style={{width: '15%'}}>{this.state.searchedName}</p>
                <p style={{width: '15%'}}>{this.state.searchedSurname}</p>
                <p style={{width: '15%', marginRight: 30}}>{this.state.searchedNumber}</p>
                
                <button onClick={() => this.setState({changePass: true})}>Şifrəni Dəyişdir</button>
            </div>

            
        }

{this.state.changePass && <div> 
        <form>
          <input placeholder="Şifrə" value={this.state.password} type="number" onChange={(event) => this.setState({password: event.target.value})}/>
          <br/>
          <input placeholder="Şifrə Təkrar" value={this.state.rePass} type="number" onChange={(event) => this.setState({rePass: event.target.value})}/>
          <br/>
      </form>
      <button onClick={this.reset}> Dəyişdir</button>
      </div>
  }
        </div>
        
    );
  }
}
export default Sixth;
