import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import axios from 'axios';
import config from "../../config"

const fileTypes = ["PDF"];


function DragDropZamin({number, token, user, postTracks}) {

  const [file, setFile] = useState(null);
  
  const [check, setCheck] = useState(null);
  const handleChange = (file) => {
    console.log(file);

    setFile(file);
  };
  const uploadFile = () => {
      let data = new FormData();
      data.append('file', file);
      data.append('mobileNumber', number);
      data.append('check', check);
      data.append('user', user);
      try {

          axios.post("http://"+config.ip+"/api/files/uploadZaminContract", data,
            {headers: {'x-access-token': token }},
          ).then(res => { 
            alert("Zamin Sənədi Uğurla göndərildi!")
            window.location.reload()
          });


      } catch(error) {
        console.log(error)
      }

  };
  return (
    <div>
      <div>
          <strong style={{fontSize: 20}}>Seçilən istifadəçi: {user}</strong>
          <br/>

        </div>
        <div>
          <strong>İstifadəçinin hazırki tələbləri</strong>
          <br/>
          {
            postTracks.map(
              pt=> 
              {
                var date = new Date(pt.createdAt)
                return <label>
                  <input 
                  type="radio"
                  name="stage"
                  id={pt._id}
                  onChange={() => setCheck(pt._id)}/> {date.getHours() + ":" + date.getMinutes() +", "+date.toLocaleDateString()} <br/></label>
              }
            )
            
          }
          <label> <input id={0} type="radio" name="stage" onChange={() => setCheck(0)}/> İlk dəfə</label>
        </div>
        <strong style={{fontSize: 18}}>Zamin istifadəçi: {number}</strong>
      <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
      <button onClick={uploadFile}> Göndər</button>
    </div>
  );
}

export default DragDropZamin;
