import React from "react";
import axios from "axios";
import config from "../../config"



class Search extends React.Component {

  state ={
    token: localStorage.getItem("token")

  }
// Function to convert the JSON(Array of objects) to CSV.
arrayToCsv = (headers, data) => {
    const csvRows = [];
     // getting headers. 
    const headerValues = headers.map(header => header.label); 
    csvRows.push(headerValues.join(',')); // Push into array as comma separated values
    // Getting rows. 
    for (const row of data) { 
        console.log("ArraytoCSV", row)
        const rowValues = headers.map(header => { 
    const escaped = ('' + row[header.key]).replace(/"/g, '\\"');
     return `"${escaped}"`; // To escape the comma in a address like string.
     }); 

     console.log("rowValues", rowValues)
    csvRows.push(rowValues.join(',')); // Push into array as comma separated values. 
    } 
    return csvRows.join('\n'); // To enter the next rows in the new line '\n' 
    };

// Function to download the generated CSV as a .csv file.
download = (data, fileName) => {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName + '.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
   };

  getCSVFile = () => {
    
    axios
      .get("http://"+config.ip+"/api/files/getBackupFiles", {
        headers: {
          "x-access-token": this.state.token
        }
      })
      .then((response) => {
        console.log(response)
        const header = [
            { label: "Məbləğ", key: "amountOfMoney" },
            { label: "Tarix", key: "date" },
            { label: "Saat", key: "clock" },
            { label: "Mobil Nömrə", key: "mobileNumber" },
            { label: "Müddət", key: "time" },
            { label: "Ad", key: "name" },
            { label: "Soyad", key: "surname" },
            { label: "Mərhələ", key: "status" },
            
            
           ];
        const csvData=this.arrayToCsv(header,response.data);
        const currentDate = new Date().toLocaleDateString('en-GB').split('/').join('.')
        console.log(currentDate)
        this.download(csvData,'İstəklər_'+currentDate);
      })
      .catch((error) => {
          console.log(error)
      });
  };


  render(){
    return (
      <div style={{display: 'flex',alignContent: 'center', justifyContent: 'center'}}>
          <button style={{fontSize: 30}}onClick={() => this.getCSVFile()}>Gələn istəkləri Excel`ə yüklə <img src={require('../../../assets/icons/inbox.png')}/></button>
        
        
      </div>
    );
  }
  
};

export default Search;
